import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  UserStatistics,
  ZeloResponse,
  ResponseNavigationEvent,
  ResponseVideoEvent,
  EndUserSettings,
  PreferredChannel,
  StepTypeUnion,
  Whitelabel
} from 'src/types';
import { getBrowserMetaData } from './../shared/enduser.util';

const RESPONSES_URL = `${environment.api}/v1/responses`;
const USERS_URL = `${environment.api}/v1/users`;
const ORGANIZATIONS_URL = `${environment.api}/v1/organizations`;
const URL = `${environment.api}/v1`;
@Injectable({ providedIn: 'root' })
export class EnduserService {
  constructor(private http: HttpClient) {}

  getZeloResponse(
    responseId: string,
    sessionStart?: boolean
  ): Observable<ZeloResponse> {
    const metaData = getBrowserMetaData();

    return this.http.get<ZeloResponse>(
      `${RESPONSES_URL}/${responseId}${
        sessionStart ? `?session_start=true&meta=${metaData}` : ''
      }`
    );
  }

  getZeloStatistics(userId: string): Observable<UserStatistics> {
    return this.http
      .get<UserStatistics>(`${USERS_URL}/${userId}/responses`)
      .pipe(
        catchError(() =>
          of({
            quickestSentToCompleted: 0,
            slowestSentToCompleted: 0,
            avgSentToCompleted: 0,
            receivedZelosCount: 0,
            openedZelosPercent: 0,
            completedZelosCount: 0,
            responses: [],
            organization: {
              id: '',
              name: '',
              logo: ''
            },
            user: {
              id: '',
              email: '',
              firstname: '',
              lastname: ''
            }
          })
        )
      );
  }

  lookupUserIdForTeams(tenantId: string, remoteId: string) {
    const url = `${USERS_URL}/lookup?channel=teams&tenantId=${tenantId}&remoteId=${remoteId}`;
    return this.http.get<{ id: string }>(url);
  }

  postZeloResponse(
    zeloResponse: ZeloResponse,
    isTest?: boolean
  ): Observable<ZeloResponse> {
    let url = `${RESPONSES_URL}`;
    if (isTest) {
      url += `?isTest=true`;
    }
    return this.http.post<ZeloResponse>(url, zeloResponse);
  }

  updateZeloResponseStep(
    step: StepTypeUnion,
    responseId: string,
    isTest?: boolean
  ): Observable<ZeloResponse> {
    let url = `${RESPONSES_URL}/step`;
    if (isTest) {
      url += `?isTest=true`;
    }
    return this.http.put<ZeloResponse>(url, { step, responseId });
  }

  checkpointZeloResonse(responseId: string, zeloResponse: ZeloResponse) {
    return this.http.post<ZeloResponse>(
      `${RESPONSES_URL}/${responseId}/checkpoint`,
      zeloResponse
    );
  }

  retakeTest(responseId: string) {
    return this.http.post(`${RESPONSES_URL}/${responseId}/retake`, null);
  }

  registerNavigation(data: ResponseNavigationEvent) {
    return this.http.post(
      `${RESPONSES_URL}/${data.responseId}/navigation`,
      data
    );
  }

  registerVideoActivity(data: ResponseVideoEvent) {
    return this.http.post(
      `${RESPONSES_URL}/${data.responseId}/video-activity`,
      data
    );
  }

  postEndUserSettings(userId: string, endUserSettings: EndUserSettings) {
    return this.http.post(`${USERS_URL}/${userId}/settings`, endUserSettings);
  }

  getEndUserSettings(userId: string) {
    return this.http.get<EndUserSettings>(`${USERS_URL}/${userId}/settings`);
  }

  getAvailableChannelsForOrganization(organizationId: string) {
    return this.http.get<PreferredChannel[]>(
      `${ORGANIZATIONS_URL}/${organizationId}/available_channels`
    );
  }

  getColorPalette(responseId: string): Observable<Whitelabel> {
    return this.http.get<Whitelabel>(
      `${URL}/organizations/color_palette?responseId=${responseId}`
    );
  }

  getCourseCertificate(responseId: string) {
    return this.http.post<{ pdf: string }>(
      `${RESPONSES_URL}/${responseId}/certificate`,
      {}
    );
  }
}
