import { ZeloResponse } from 'src/types';
import { ConsentStepInterface } from 'src/types';

export const validateZeloResponseForConsent = (
  zeloResponse: ZeloResponse
): boolean => {
  const { steps } = zeloResponse;

  const consentSteps = steps.filter((step) => step.stepType === 'consent');
  return !consentSteps.length
    ? true
    : consentSteps.every((step: ConsentStepInterface) => {
        const { responses } = step;

        return responses
          ? responses
              .filter((option) => option.isCorrect)
              .every((option) => {
                return option.isSelected === option.isCorrect;
              })
          : false;
      });
};

export const getBrowserMetaData = () => {
  return btoa(
    JSON.stringify({
      screenWidth: innerWidth,
      screenHeight: innerHeight
    })
  );
};
